<template>
    <v-row dense id="upload_files">
        <v-col cols="12" class="file_zone" :class="{is_disabled:is_disabled, list_view:list_view}">
            <input type="file" id="files" :ref="ref_name" :accept="accepted_files" :multiple="is_multiple"
                   :disabled="is_disabled" v-on:change="handleFiles()"/>
            <div v-if="files.length === 0" class="default_layout">
                <div class="file_zone_text">{{$t('uploads.drop_files')}}</div>
                <div class="file_zone_separator">{{$t('general.or')}}</div>
                <v-btn class="primary mt-2" tile @click="handleFiles()">
                    <v-icon left>mattach_file</v-icon>
                    {{$t('uploads.add_files')}}
                </v-btn>
            </div>
            <div v-else class="file_listings_layout">
                <div class="d-flex flex-wrap" :class="{'justify-center':!list_view}">
                    <div class="file_listing" v-for="(file, key) in files" :key="key">
                        <div class="img_layer">
                            <img class="preview" :ref="'preview'+parseInt(key)"/>
                        </div>
                        <div class="top_container">
                            <div class="file_name">{{file.name}}</div>
                            <v-btn v-if="!is_disabled" text icon class="remove_btn" x-small @click="removeFile(key)"
                                   :title="$t('general.remove')">
                                <v-icon>clear</v-icon>
                            </v-btn>
                        </div>
                        <div class="info_container">
                            <div class="file_size">{{$Helper.methods.formatBytes(file.size)}}</div>
                            <v-btn text icon class="download_btn" x-small :href="download_data(file)"
                                   :download="file.name" :title="$t('general.download')">
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script type="text/babel">
    import Compressor from 'compressorjs';

    export default {
        components: {
            Compressor
        },
        props: {
            postedFiles: {type: Array},
            accepted_files: {type: String, default: 'image/png,image/jpeg,application/pdf'},
            is_multiple: {type: Boolean, default: false},
            max_files: {type: Number, default: 20},
            is_disabled: {type: Boolean, default: false},
            ref_name: {type: String},
            list_view: {type: Boolean, default: false},
            filename: {type: String}
        },
        data() {
            return {
                files: [],
                loading_photos_dialog: false
            }
        },
        watch: {
            // get files from parent component
            postedFiles(val, oldVal) {
                this.files = val;
                this.getImagePreviews();
            }
        },
        methods: {
            // when select files on files input, add them to files array and call function to create their preview
            handleFiles() {
                let files = this.$refs[this.ref_name].files;

                for (let i = 0; i < files.length; i++) {

                    // create new file promise
                    let new_file_promise = new Promise((resolve, reject) => {

                        let current_file = files[i];

                        // file is pdf
                        if (current_file.type === 'application/pdf') {

                            let filename = this.filename ? `${this.filename}.${current_file.name.split('.')[1]}`
                                : current_file.name;

                            // create new file
                            resolve(new File([current_file], filename, {
                                type: current_file.type, lastModified: current_file.lastModified
                            }));

                            // file is image
                        } else {
                            const self = this;

                            // compress image
                            new Compressor(current_file, {
                                quality: 0.6,
                                success(result) {

                                    let filename = self.filename ? `${self.filename}.${result.name.split('.')[1]}`
                                        : result.name;

                                    // create new file after compression
                                    resolve(new File([result], filename, {
                                        type: result.type, lastModified: result.lastModified
                                    }));
                                }
                            });
                        }
                    });

                    new_file_promise.then(new_file => {

                        // added files don't reach the max files limit
                        if (this.files.length < this.max_files) {
                            this.files.push(new_file);
                            this.getImagePreviews();
                        } else {
                            this.$store.dispatch('showResponseMessage', {
                                response_type: 'error',
                                response: this.$i18n.t('uploads.max_limit', {number: this.max_files}),
                                response_timeout: 0, response_origin: 'custom_message'
                            });
                        }
                    });
                }
            },
            // create a review of selected files
            getImagePreviews() {
                for (let i = 0; i < this.files.length; i++) {
                    if (/\.(jpe?g|png|gif|pdf)$/i.test(this.files[i].name)) {

                        let reader = new FileReader();
                        reader.addEventListener('load', function () {
                            this.$refs['preview' + parseInt(i)][0].src = (/\.(pdf)$/i.test(this.files[i].name))
                                ? require('../assets/images/pdf.jpg') : reader.result;
                        }.bind(this), false);

                        reader.readAsDataURL(this.files[i]);
                    }
                }

                this.$emit('getFiles', this.files);
            },
            // remove preview
            removeFile(key) {
                this.files.splice(key, 1);
                this.getImagePreviews();
            },
            // create blob to download
            download_data(file) {
                return window.URL.createObjectURL(file);
            }
        }
    }
</script>